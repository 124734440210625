import React, { useState } from 'react';
import { FacebookMessengerShareButton } from 'react-share';
// ! package to copy text
import ClipboardJS from 'clipboard';
import TwitterIcon from '../../assets/twittericon.png';
import FacebookIcon from '../../assets/facebookicon.png';
import WhatsappIcon from '../../assets/whatsappicon.png';

type SocialProps = {
  id: string;
};

const SocialInvite: React.FC<SocialProps> = ({ id }) => {
  const [isActive, setIsActive] = useState(false);

  const animate = () => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 700);
  };

  new ClipboardJS('.input-clone');
  new ClipboardJS('.copy-clone');

  return (
    <section className="social-invite">
      <h3>Copy or Share your invite link</h3>
      <div className="social-flex">
        <div className="input-clone" data-clipboard-target="#referral-link" onClick={() => animate()}>
          <span className="referral-link" id="referral-link">
            {`welcome.nofusstutors.com/book/${id}`}
          </span>
          <span className={isActive ? 'copy animate' : 'copy'} onClick={() => animate()}>
            {isActive ? 'Copied!' : 'Copy'}
            <i className="far fa-copy"></i>
          </span>
        </div>
        <div className="copy-and-social">
          <div
            className={isActive ? 'copy-clone animate' : 'copy-clone'}
            data-clipboard-target="#referral-link"
            onClick={() => animate()}
          >
            <span>
              {isActive ? 'Copied!' : 'Copy'}
              <i className="far fa-copy"></i>
            </span>
          </div>
          <div className="social-share flex">
            <a
              className="twitter-share-button"
              href={`https://twitter.com/intent/tweet?text=Use%20my%20link%20and%20get%20$50%20for%20your%20first%20tutoring%20session%20with%20No%20Fuss%20Tutors!%20welcome.nofusstutors.com/book/${id}`}
              data-size="large"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={TwitterIcon} alt="Twitter Icon" title="Share on Twitter" />
            </a>
            <a className="middle-icon">
              {process.env.REACT_APP_FB_ID ? (
                <FacebookMessengerShareButton
                  // resetButtonStyle={false}
                  appId={process.env.REACT_APP_FB_ID}
                  url={`welcome.nofusstutors.com/book/${id}`}
                >
                  <img src={FacebookIcon} alt="Facebook Icon" title="Share on Facebook" />
                </FacebookMessengerShareButton>
              ) : (
                <img src={FacebookIcon} alt="Facebook Icon" title="Share on Facebook" />
              )}
            </a>
            <a
              href={`whatsapp://send?text=Get $30 for your first tutoring session with No Fuss Tutors! welcome.nofusstutors.com/book/${id}`}
            >
              <img src={WhatsappIcon} alt="Whatsapp Icon" title="Share on Whatsapp" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};
export default SocialInvite;

{
  /* <div className="sms-to"> */
}
{
  /* to Android */
}
// <a href="sms:/* phone number here */?body=/* body text here */">Link</a>
{
  /* to iOS */
}
// <a href={`sms:?&body=Use%20my%20link%20and%20get%20%2430%20for%20your%20first%20tutoring%20session%20with%20No%20Fuss%20Tutors!%20welcome.nofusstutors.com/book/${id}`}>SMS</a>
// </div>
