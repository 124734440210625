import React, { useState, useEffect } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Banner from '../Components/Book/Banner';
import Form from '../Components/Book/Form';
import { GetBaseURL } from '../Utils';

interface MatchParams {
  id: string;
}

const BookAppointment: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const [userName, setUserName] = useState<string | null>(null);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    (async () => {
      const URL = `${GetBaseURL()}/api/clients/referrals/registration/${match.params.id}?key=${
        process.env.REACT_APP_API_KEY
      }`;
      const REQUEST = await fetch(URL);

      if (REQUEST.status === 200) {
        const response = await REQUEST.json();
        setUserName(response.client.client_name);
      } else {
        setRedirect(true);
      }
    })();
  }, []);

  if (redirect) {
    return <Redirect to="/notFound" />;
  } else {
    return (
      <>
        <Banner name={userName} />
        <section className="gray-background vertical-padding">
          <div className="container">
            <Form referenceID={match.params.id} newLead={false} />
          </div>
        </section>
      </>
    );
  }
};

export default BookAppointment;
