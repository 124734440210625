import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Banner from '../Components/Book/Banner';
import Form from '../Components/Book/Form';

const NewCustomer: React.FC<RouteComponentProps> = () => {
  return (
    <>
      <Banner name={null} newLead={true} />
      <section className="gray-background vertical-padding">
        <div className="container">
          <Form newLead={true} />
        </div>
      </section>
    </>
  );
};

export default NewCustomer;
