import React, { useRef, useState } from 'react';
import Number1 from '../../assets/1.png';
import Number2 from '../../assets/2.png';
import Number3 from '../../assets/3.png';
import Number4 from '../../assets/4.png';
import Number5 from '../../assets/5.png';
import Number6 from '../../assets/6.png';
import Triangle from '../../assets/triangle.png';

import { States } from '../../Models';
import { GetBaseURL, NormalizePhone, NumbersOnly } from '../../Utils';
import ErrorHandler from '../ErrorHandler';

type FormProps = {
  referenceID?: string;
  newLead?: boolean;
};

type CountryDataCallTime = {
  phone: string;
  region: string;
  time: string;
};

type ErrorState = {
  isActive: boolean;
  message: string;
  type: 'error' | 'warning' | 'success' | 'info';
  icon: string;
};

const Form: React.FC<FormProps> = ({ referenceID, newLead }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [error, setError] = useState<ErrorState>({
    isActive: false,
    message: '',
    type: 'error',
    icon: '',
  });
  const [isDisabled, setIsDisabled] = useState(false);
  const [countryData, setCountryData] = useState<CountryDataCallTime | null>(null);
  const formRef = useRef<HTMLFormElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const stateRef = useRef<HTMLSelectElement>(null);
  const notesRef = useRef<HTMLTextAreaElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);

  const resetError = () => {
    setError(() => ({
      isActive: false,
      error: '',
      message: '',
      type: 'error',
      icon: '',
    }));
  };

  const showInputError = () => {
    setError((prev: ErrorState) => ({
      ...prev,
      isActive: true,
      message: 'Name, Email, Phone and State are required to submit the form.',
      type: 'error',
      icon: 'fas fa-times-circle',
    }));
  };

  const SubmitHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    // declare phone number variable and remove all special characters for post request
    let phoneNumber;

    setIsDisabled(true);
    let canSubmitData = true;

    if (!nameRef.current?.value || nameRef.current?.value === '') {
      canSubmitData = false;
      showInputError();
    }

    if (!emailRef.current?.value || emailRef.current?.value === '') {
      canSubmitData = false;
      showInputError();
    }

    if (!phoneRef.current?.value || phoneRef.current?.value === '') {
      canSubmitData = false;
      showInputError();
    } else {
      phoneNumber = NumbersOnly(phoneRef.current?.value);
    }

    if (!stateRef.current?.value || stateRef.current?.value === '') {
      canSubmitData = false;
      showInputError();
    }

    if (canSubmitData) {
      //collect valid data and store in object
      let falseData = false;

      const REFERRAL_REQUEST_BODY = {
        full_name: nameRef.current?.value,
        email: emailRef.current?.value,
        // phone_number: phoneRef.current?.value,
        phone_number: phoneNumber,
        state: stateRef.current?.value,
        notes: notesRef.current?.value,
        time_to_call: dateRef.current?.value,
        referee_id: referenceID,
      };

      const SIGNUP_REQUEST_BODY = {
        clientData: {
          full_name: nameRef.current?.value,
          email: emailRef.current?.value,
          // phone_number: phoneRef.current?.value,
          phone_number: phoneNumber,
          state: stateRef.current?.value,
          notes: notesRef.current?.value,
          time_to_call: dateRef.current?.value,
        },
      };

      if (
        REFERRAL_REQUEST_BODY.full_name === undefined ||
        REFERRAL_REQUEST_BODY.email === undefined ||
        REFERRAL_REQUEST_BODY.phone_number === undefined ||
        REFERRAL_REQUEST_BODY.state === undefined
      ) {
        falseData = true;
      }

      if (!falseData) {
        //send post request to endpoint on the server -- utility function for baseURL depending on ENV variable
        const URL = `${GetBaseURL()}/api/clients/${newLead ? 'signup/welcomePage' : 'referrals/newClient'}?key=${
          process.env.REACT_APP_API_KEY
        }`;
        const REQUEST = await fetch(URL, {
          method: 'POST',
          body: newLead ? JSON.stringify(SIGNUP_REQUEST_BODY) : JSON.stringify(REFERRAL_REQUEST_BODY),
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (REQUEST.status === 200) {
          const RESPONSE = await REQUEST.json();
          if (RESPONSE.time_to_call !== undefined) {
            const callTime: CountryDataCallTime = {
              time: RESPONSE.time_to_call.time_to_call || '',
              region: RESPONSE.time_to_call.region || 'Florida',
              phone: RESPONSE.time_to_call.phone || '(305) 590-5573',
            };

            setCountryData(callTime);
          }
          setSubmitSuccess(true);

          if (formRef.current) {
            formRef.current.reset();
          }
        } else {
          setError((prev: ErrorState) => ({
            ...prev,
            isActive: true,
            message: 'Failed to submit the form, please try again.',
            type: 'error',
            icon: 'fas fa-times-circle',
          }));
        }
      }

      setIsDisabled(false);
    } else {
      setIsDisabled(false);
    }
  };

  return (
    <form className="form" onSubmit={SubmitHandler} ref={formRef}>
      <div className="field-image-container" id="name">
        <div className="step-image">
          <img src={Number1} alt="First Question" />
          <label>What is your first and last name?</label>
        </div>
        <div className="form-field">
          <label>What is your first and last name?</label>
          <input ref={nameRef} type="text" placeholder="Enter first and last name..." />
        </div>
      </div>

      <div className="field-image-container">
        <div className="step-image">
          <img src={Number2} alt="Second Question" />
          <label>How about your email?</label>
        </div>
        <div className="form-field">
          <label>How about your email?</label>
          <input ref={emailRef} type="email" placeholder="Enter email address..." />
        </div>
      </div>

      <div className="field-image-container">
        <div className="step-image">
          <img src={Number3} alt="Third Question" />
          <label>Enter a phone number</label>
        </div>
        <div className="form-field">
          <label>Enter a phone number</label>
          <input
            ref={phoneRef}
            type="text"
            placeholder="Enter phone number..."
            onBlur={(e) => {
              if (phoneRef.current?.value) {
                NormalizePhone(e);
              }
            }}
          />
        </div>
      </div>

      <div className="field-image-container">
        <div className="step-image">
          <img src={Number4} alt="Fourth Question" />
          <label>Where do you live?</label>
        </div>
        <div className="form-field">
          <label>Where do you live?</label>
          <div className="select-container">
            <select ref={stateRef}>
              {States.map((state, index) => {
                if (state.label === 'Province / State') {
                  return (
                    <option key={index} disabled selected>
                      {state.label}
                    </option>
                  );
                } else {
                  return (
                    <option key={index} value={state.value}>
                      {state.label}
                    </option>
                  );
                }
              })}
            </select>
            <div className="select-icon">
              <img src={Triangle} alt="select-icon" />
            </div>
          </div>
        </div>
      </div>
      <div className="field-image-container">
        <div className="step-image">
          <img src={Number5} alt="Fifth Question" />
          <label>
            Provide a short description of your needs. (student(s): age, special needs, learning goals, etc.)
          </label>
        </div>
        <div className="form-field">
          <label>
            Provide a short description of your needs. (student(s): age, special needs, learning goals, etc.)
          </label>
          <textarea ref={notesRef} placeholder="Type your answer here..." />
        </div>
      </div>

      <div className="field-image-container">
        <div className="step-image">
          <img src={Number6} alt="Sixth Question" />
          <label>Pick a date and time for us to call you</label>
        </div>
        <div className="form-field">
          <label>Pick a date and time for us to call you</label>
          <input ref={dateRef} type="text" placeholder="Monday at 9am EST" />
        </div>
      </div>

      {submitSuccess ? (
        <ErrorHandler
          message={
            countryData !== null
              ? `Thanks for your info! In case you are in a different part of the country, we will be calling you from our ${countryData.region} number: ${countryData.phone}`
              : 'Thanks for your info! We will be in touch shortly. Click here to visit our Homepage.'
          }
          type="success"
          handler={() => {
            setSubmitSuccess(false);
            window.location.assign('https://www.nofusstutors.com/');
          }}
        />
      ) : null}

      {error.isActive ? (
        <ErrorHandler message={error.message} type={error.type} handler={() => resetError()} icon={error.icon} />
      ) : null}

      <button disabled={isDisabled}>{newLead ? 'Free Consultation' : 'Accept Invitation'}</button>
    </form>
  );
};

export default Form;
