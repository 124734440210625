import React, { useState, useEffect } from 'react';
import Banner from '../Components/Share/Banner';
import EmailPhoneInvite from '../Components/Share/EmailPhoneInvite';
import SocialInvite from '../Components/Share/SocialInvite';
import Credits from '../Components/Share/Credits';
import Info from '../Components/Share/Info';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { GetBaseURL } from '../Utils';
interface MatchParams {
  id: string;
}

const ShareWithFriends: React.FC<RouteComponentProps<MatchParams>> = ({ match }) => {
  const [numberOfCredits, setNumberOfCredits] = useState(0);
  const [clientName, setClientName] = useState('');
  const [redirect, setRedirect] = useState(false);

  // console.log('match params', match.params); //MongoID will be accessible by match.params.id

  useEffect(() => {
    (async () => {
      const URL = `${GetBaseURL()}/api/clients/referrals/sharePage/${match.params.id}?key=${
        process.env.REACT_APP_API_KEY
      }`;
      const REQUEST = await fetch(URL);

      if (REQUEST.status === 200) {
        const response = await REQUEST.json();
        console.log(response);
        setNumberOfCredits(response.client.referral_completed_count | 0);
        setClientName(typeof response.client.client_name === 'string' ? response.client.client_name : '');
      } else {
        setRedirect(true);
      }
    })();
  });

  if (redirect) {
    return <Redirect to="/notFound" />;
  } else {
    return (
      <>
        <Banner name={clientName} />
        <div className="container content-container">
          <section className="how-to-share">
            <EmailPhoneInvite id={match.params.id} />
            <SocialInvite id={match.params.id} />
          </section>
          <Credits value={numberOfCredits} />
        </div>
        <Info />
      </>
    );
  }
};

export default ShareWithFriends;
