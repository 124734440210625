import React from 'react';

interface ErrorProps {
  message: string;
  type: 'error' | 'warning' | 'success' | 'info';
  handler?: () => void;
  icon?: string;
}

const ErrorHandler: React.FC<ErrorProps> = ({ message, type, handler, icon }) => {
  const classVar = 'error-handler isa_' + type;
  return (
    <div className={classVar} onClick={handler}>
      <i className={icon ? icon : 'fas fa-exclamation-triangle'}></i>
      {message}
    </div>
  );
};

export default ErrorHandler;