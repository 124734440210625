import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Header from './Components/Header';
import ShareWithFriends from './Pages/ShareWithFriends';
import BookAppointment from './Pages/BookAppointment';
import ErrorPage from './Pages/404ErrorPage';
import NewCustomer from './Pages/NewCustomer';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Switch>
        <Route path="/notFound" exact component={ErrorPage} />

        <Route
          path="/share/:id"
          render={(props) => {
            return <ShareWithFriends {...props} />;
          }}
        />

        <Route
          path="/book/:id"
          render={(props) => {
            return <BookAppointment {...props} />;
          }}
        />

        <Route path="/" render={(props) => <NewCustomer {...props} />} />
      </Switch>
    </div>
  );
};

export default App;
