import React from 'react';
import UFOImage from '../assets/404image.png';

const ErrorPage: React.FC = () => {
  return (
    <section className="error404 container">
      <div>
        <img src={UFOImage} alt="Not Found!" title="Not Found" style={{ height: '600px' }} />
      </div>
      <div className="error-text">
      <h3>Whoops! This page doesn't exist.</h3>
      <p>
        We can't seem to find the page you are looking for. It may have expired, had its name changed or is temporarily
        unavailable.
      </p>
      <button>
        <a href="https://nofusstutors.com" title="No Fuss Tutors Home Page" aria-label="No Fuss Tutors Home Page">Go To Home Page</a>
      </button>
      </div>
    </section>
  );
};

export default ErrorPage;
