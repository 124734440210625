import React from 'react';

type CreditProps = {
  value: number;
};

const Credits: React.FC<CreditProps> = ({ value }) => {
  return (
    <section className="credits">
      <div className="gray-background">
        <h3>Your Credits</h3>
        <div className="flex space-between">
          <span>Earned</span>
          <span>$ {value * 30}</span>
        </div>
      </div>
      <div className="potential-earnings">
        <p>
          If 3 people join, you get <span>$90!</span>
        </p>
        <p>
          If 5 people join, you get <span>$150!</span>
        </p>
        <p>
          If 10 people join, you get <span>$300!</span>
        </p>
        <p className="strong">
          <strong>There are no limits!</strong>
        </p>
      </div>
    </section>
  );
};
export default Credits;
