import React from 'react';
import Logo from '../assets/logo-original.png';

const Header: React.FC = () => {
  return (
    <header>
      <section className="top-section">
        <div className="nft-info container">
          <div className="flex">
            <div className="call">
              <i className="fa fa-phone"></i>
              <a href="tel:+6469069648">Call or Text Us (646) 906-9648</a>
            </div>
            <div className="email">
              <i className="fa fa-envelope"></i>
              <a href="mailto:info@nofusstutors.com">info@nofusstutors.com</a>
            </div>
          </div>
          <div className="g-star-holder">
            <div className="block">
              <i className="fa fa-google"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
              <i className="fa fa-star"></i>
            </div>
          </div>
        </div>
      </section>
      <section className="container flex space-between">
        <div className="logo">
          <a href="https://www.nofusstutors.com/" target="_blank" rel="noopener noreferrer">
            <img src={Logo} alt="No Fuss Tutors logo" title="No Fuss Tutors"></img>
          </a>
        </div>
        <div className="phone-dropdown-container">
          <div className="phone">
            <a href="tel:+16469069648" className="main-number">NY: (646) 906-9648</a>
            <i className="fas fa-caret-down"></i>
          </div>
          <div className="phone-options">
            <a href="tel:+2133377441">CA: (213) 337-7441</a>
            <a href="tel:+6473706323">TO: (647) 370-6323</a>
            <a href="tel:+3055905573">FLA: (305) 590-5573</a>
          </div>
        </div>
      </section>
    </header>
  );
};

export default Header;
