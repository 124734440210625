import React from 'react';
import One from '../../assets/1.png';
import Two from '../../assets/2.png';
import Three from '../../assets/3.png';

const Info: React.FC = () => {
  return (
    <section className="info-container">
      <div className="container">
        <h2>How does it work, you ask?</h2>
        <div className="flex steps">
          <article>
            <img src={One} alt="Step One" title="Step One" />
            <h3>Invite Friends</h3>
            <p>Invite friends new to No Fuss Tutors to book a session by sharing your link.</p>
          </article>
          <article className="middle">
            <img src={Two} alt="Step Two" title="Step Two" />
            <h3>Friends Book a Session</h3>
            <p>Friends who book a session with your link get $30 towards their first session.</p>
          </article>
          <article>
            <img src={Three} alt="Step Three" title="Step Three" />
            <h3>Earn a $30 credit</h3>
            <p>For every friend that completes their first session, you'll receive a $30 credit - on us!</p>
          </article>
        </div>
      </div>
    </section>
  );
};
export default Info;
