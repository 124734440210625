import React from 'react';

type BannerProps = {
  name: string | null;
  newLead?: boolean;
};

const Banner: React.FC<BannerProps> = ({ name, newLead }) => {
  return (
    <section className={newLead ? 'new' : 'book'}>
      <div className="banner book-banner">
        <div className="container">
          {newLead ? (
            <>
              <span>Affordable & Flexible</span>
              <h1>Book Your Expert Online Tutor</h1>
              <p>Fill out the form below to inquire about booking a tutoring session</p>
              <div className="button-container">
                <button>
                  <a href="#name">Free Consultation</a>
                </button>
              </div>
            </>
          ) : (
            <>
              <span>You Have Been Invited {name === null ? null : `By ${name}`}</span>
              <h1>
                {name === null
                  ? 'Receive $30 for your first lesson with No Fuss Tutors!'
                  : `${name.split(' ')[0]} sent you $30 for your first lesson with No Fuss Tutors!`}
              </h1>
              <p>
                Fill out the form below <strong className="white">within the next 7 days</strong> to accept your{' '}
                <strong className="white">$30 gift from {name !== null ? name.split(' ')[0] : 'your friend'}</strong>!
              </p>
              <div className="button-container">
                <button>
                  <a href="#name">Accept Invitation</a>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default Banner;
