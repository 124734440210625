import React from 'react';

type ShareBannerProps = {
  name: string;
};

const Banner: React.FC<ShareBannerProps> = ({ name }) => {
  return (
    <section className="share">
      <div className="banner share-banner">
        <span>Refer & Earn</span>
        <h1>
          Hi {name.split(' ').length > 1 ? name.split(' ')[0] : name}!
          <br />
          Share with friends,
          <br />
          Make $30 each time.
        </h1>
      </div>
    </section>
  );
};

export default Banner;
