export const capitalize = (str:string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const FormatName = (str:string) => {
  const formatName = str.split(' ').map(name => capitalize(name)).join(' ');
  return formatName;
};

export const NormalizePhone = (event: React.ChangeEvent<HTMLInputElement>) => {
  const x = event.target.value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
  if (event.target.value.length < 1) {
    return x;
  }

  if (event.target.value.length !== 10) {
    return x;
  }

  event.target.value = '(' + x![1] + ') ' + x![2] + '-' + x![3];
};

//! returns user input as numeric values only
export const NumbersOnly = (str:string) => {
  return str.trim().replace(/[^0-9]+/g, '').replace(/\s/g, '');
};

// ! format phone number for display in app
export const FormatPhoneNumber = (str:string) => {
  const cleaned = ('' + str).replace(/D/g, '');
  const match = cleaned.match(/^(d{3})(d{3})(d{4})$/);
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  return null;
};

//! returns true if valid
export const ValidateEmail = (email:string) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

//! returns true if valid US number
export const ValidatePhone = (phone:string) => {
  let numToCheck = NumbersOnly(phone);
  
  if (parseInt(numToCheck[0]) === 1) {
    numToCheck = numToCheck.substring(1);
  }

  return numToCheck.length === 10 ? true : false;
};