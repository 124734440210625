import React, { useState, useRef } from 'react';
import { GetBaseURL } from '../../Utils';
import ErrorHandler from '../ErrorHandler';

type EmailPhoneProps = {
  id: string;
};
const EmailPhoneInvite: React.FC<EmailPhoneProps> = ({ id }) => {
  const [contacts, setContacts] = useState<string[]>([]);
  const [inputError, setInputError] = useState(false);
  const [sendSuccess, setSendSuccess] = useState(false);
  const [sendError, setSendError] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  // normalize phone number
  const formatPhoneNumber = (str: string) => {
    //Filter only numbers from the input
    const cleaned = ('' + str).replace(/\D/g, '');
    //Check if the input is of correct length
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  };

  const clearContacts = () => {
    setContacts([]);
  };

  const removeWhiteSpace = (input: string) => {
    return input.trim().replace(/\s\s+/g, '');
  };

  const validateInput = (input: string) => {
    if (!input.includes('@')) {
      // if the input is not an email address, remove all special characters
      input = input.replace(/[^\w\s]/gi, '');
      const phonePattern = /^\d{10}$/;
      if (input.search(phonePattern) === -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  const addContacts = (input: string) => {
    setContacts((prev: string[]) => {
      return [...prev, input];
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (inputRef.current?.value.includes(',')) {
      // split string from comma and remove white space
      const input = removeWhiteSpace(inputRef.current.value.split(',')[0]);
      // validate input and set state if pass
      if (validateInput(input)) {
        addContacts(input);
        inputRef.current.value = '';
      } else {
        setInputError(true);
        inputRef.current.value = '';
      }
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.persist();
    if (inputRef.current?.value) {
      const input = removeWhiteSpace(inputRef.current.value.split(',')[0]);

      if (validateInput(input)) {
        addContacts(input);
        inputRef.current.value = '';
      } else {
        setInputError(true);
        inputRef.current.value = '';
      }
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && inputRef.current?.value) {
      const input = removeWhiteSpace(inputRef.current.value);
      if (validateInput(input)) {
        addContacts(input);
        inputRef.current.value = '';
      } else {
        setInputError(true);
        inputRef.current.value = '';
      }
    }
  };

  const deleteContact = (referContact: string) => {
    const newContacts = contacts.filter((contact) => {
      return contact !== referContact;
    });
    setContacts(newContacts);
  };

  const sendContacts = async (id: string, contacts: string[]) => {
    const reqBody = { contacts: contacts, mongo_id: id };
    const url = `${GetBaseURL()}/api/clients/referrals/share?key=${process.env.REACT_APP_API_KEY}`;

    try {
      const request = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(reqBody),
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (request.status === 200) {
        console.log('Success!');
        clearContacts();
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return (
    <>
      <section className="earn">
        <h2>Earn $30 for every new friend you refer</h2>
        <p>
          Invite friends to use No Fuss Tutors. They'll get <strong>$30</strong> to use towards their first tutoring
          session - and once their session is complete, you'll get <strong>$30 too!</strong>
        </p>
      </section>
      <div className="contacts-email-input-container">
        <div className="error-container">
          {sendSuccess ? (
            <ErrorHandler
              message="Invitation(s) successfully sent!"
              type="success"
              icon="fas fa-check-circle"
              handler={() => {
                setIsDisabled(false);
                setSendSuccess(false);
                setSendError(false);
                setInputError(false);
              }}
            />
          ) : null}
          {sendError ? (
            <ErrorHandler
              message="Invitation(s) could not be sent. Please check inputs and try again."
              type="error"
              icon="fas fa-times-circle"
              handler={() => {
                setIsDisabled(false);
                setSendError(false);
                setInputError(false);
              }}
            />
          ) : null}
          {inputError ? (
            <ErrorHandler
              message="Mobile numbers can only contain numbers with the area code."
              type="warning"
              handler={() => {
                setInputError(false);
              }}
            />
          ) : null}
        </div>
        <div className="contacts-container">
          {contacts.map((contact, index) => {
            return (
              <div className="contact-container" key={index}>
                <span className="contact">{!contact.includes('@') ? formatPhoneNumber(contact) : contact}</span>
                <span
                  className="x"
                  onClick={() => {
                    deleteContact(contact);
                  }}
                >
                  <i className="fas fa-times"></i>
                </span>
              </div>
            );
          })}
        </div>
        <div className="email-input">
          <input
            name="email-or-phone"
            className="email-or-phone"
            type="text"
            title="email or phone"
            ref={inputRef}
            placeholder="Invite via email or phone"
            onChange={(e) => {
              handleInputChange(e);
            }}
            onBlur={(e) => handleBlur(e)}
            onKeyUp={(e) => {
              handleKeyUp(e);
            }}
          />
          <button
            disabled={isDisabled}
            onClick={async () => {
              setIsDisabled(true);

              const returnValue = await sendContacts(id, contacts);

              returnValue ? setSendSuccess(true) : setSendError(true);
            }}
          >
            Send Invites
          </button>
        </div>
      </div>
    </>
  );
};

export default EmailPhoneInvite;
